.home-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: #f5f5f5;
}

.cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    margin-top: 20px;
}

h1 {
    text-align: left;
    color: #333;
}