/* Estilos específicos para el componente LoginForm */
.login-form-container {
    display: flex;
    height: 100vh;
    margin: 0;
    flex-direction: row;
    overflow: hidden; /* Evita que el contenido se desborde */
}

.login-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%; /* Ajuste del ancho para móviles */
    max-width: 100%; /* Ancho máximo para pantallas grandes */
    height: auto; /* Ajustar altura automáticamente */
    text-align: center;
    position: relative; /* Para manejar la posición de los logos */
    z-index: 2; /* Asegura que el formulario esté por encima de la imagen */
}

.logos {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 30px;
    position: absolute; /* Fija la posición de los logos en el contenedor del formulario */
    top: 10px; /* Espacio desde la parte superior del contenedor del formulario */
    left: 0;
    right: 0;
    z-index: 1;
}

.logo {
    height: 60px; /* Ajusta la altura de los logos */
    max-width: 45%; /* Limita el ancho de los logos para evitar que se desborden */
    object-fit: contain; /* Mantiene la proporción del logo */
}

h2 {
    margin: 20px 0; /* Espaciado general */
    font-size: 24px;
}

.error {
    background-color: #f8d7da;
    color: #721c24;
    padding: 10px;
    border: 1px solid #f5c6cb;
    border-radius: 4px;
    margin-bottom: 20px;
    width: 100%; /* Ancho completo del contenedor del formulario */
    max-width: 400px; /* Ancho máximo para mantener la coherencia */
}

.form-group {
    width: 100%; /* Ancho completo del contenedor del formulario */
    margin-bottom: 20px;
}

.input-group-text {
    background-color: #fff;
    border: 1px solid #ddd;
}

button {
    width: 100%; /* Botón ocupa el ancho completo del contenedor */
    padding: 10px;
    background-color: #004085;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

button:hover {
    background-color: #002752;
}

.login-image {
    position: relative;
    width: 100%;
    height: 100vh;
    display: block; /* Asegura que la imagen de fondo se muestre en pantallas grandes */
    overflow: hidden; /* Asegura que no haya espacios en blanco */
}

.login-image img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Asegura que la imagen cubra todo el contenedor */
    object-position: center; /* Centra la imagen dentro de su contenedor */
}

@media (max-width: 767px) {
    .login-form-container {
        flex-direction: column; /* En pantallas pequeñas, el formulario se muestra encima de la imagen */
    }

    .login-form {
        width: 100%;
        max-width: none; /* Elimina el límite máximo en pantallas pequeñas */
        margin: 20px auto; /* Margen para separación en pantallas pequeñas */
    }

    .logos {
        position: relative; /* Mueve los logos dentro del formulario en pantallas pequeñas */
        top: auto;
        bottom: auto;
        flex-direction: row; /* Coloca los logos en fila en pantallas pequeñas */
        margin-bottom: 10px; /* Espacio debajo de los logos */
    }

    .logo {
        height: 50px; /* Ajusta la altura de los logos en pantallas pequeñas */
        max-width: 60%; /* Limita el ancho de los logos en pantallas pequeñas */
        margin-right: 10px; /* Espacio entre los logos */
    }
}

/* Ajuste específico para pantallas grandes */
@media (min-width: 768px) {
    h2 {
        margin-bottom: 40px; /* Aumenta el espacio debajo del título en pantallas grandes */
    }
}
