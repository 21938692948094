.card {
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    width: 250px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: left;
  }
  
  .card p {
    margin: 0;
    color: #555;
  }
  
  .card h2 {
    margin: 10px 0;
    color: #333;
  }
  
  .status {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-top: 10px;
  }
  
  .status.green {
    background-color: green;
  }
  
  .status.yellow {
    background-color: yellow;
  }
  
  .status.red {
    background-color: red;
  }