.historial-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

h1 {
  margin-bottom: 20px;
}

.historial-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.historial-list {
  flex: 1;
  min-width: 300px;
  max-width: 600px;
}

.historial-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.historial-item .icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
}

.historial-item.inversor-normal .icon {
  background-color: green;
}

.historial-item.inversor-dañado .icon {
  background-color: yellow;
}

.historial-item.inversor-fallando .icon {
  background-color: red;
}

.historial-item .status {
  flex: 1;
  font-weight: bold;
}

.historial-item .date {
  color: gray;
}

.historial-cards {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-left: 20px;
  min-width: 300px;
  max-width: 400px;
}

.card {
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.card-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.card-value {
  font-size: 1.2em;
}

/* Pagination styles */
.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
}

.pagination li {
  margin: 0 5px;
}

.pagination li a {
  text-decoration: none;
  color: #007bff;
  cursor: pointer;
}

.pagination li.active a {
  font-weight: bold;
  color: #495057;
}