body, html, #root {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
}

/* Estilos generales para la app */
.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content {
  flex: 1;
  padding: 20px;
  margin-left: 250px; /* Ajusta esto según el ancho de tu sidebar */
}

@media (max-width: 768px) {
  .content {
    margin-left: 100px; /* Ajusta esto según el ancho de tu sidebar en pantallas pequeñas */
  }
}

@media (max-width: 480px) {
  .content {
    margin-left: 70px; /* Ajusta esto según el ancho de tu sidebar en pantallas muy pequeñas */
  }
}

footer {
  background-color: #f8f9fa;
  padding: 20px;
  text-align: center;
  border-top: 1px solid #ddd;
}
