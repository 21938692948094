.imagen-paneles {
    width: 80%;
    height: auto;
  }
  
  .ubicacion {
    margin-right: 16px; /* Adjust as necessary */
  }
  
  .temperatura {
    margin-left: 16px; /* Adjust as necessary */
  }
  