/* src/components/Sidebar.css */
.sidebar {
    width: 250px;
    background-color: #2c3e50;
    color: #ecf0f1;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
    position: fixed;
}

.sidebar-logo {
    margin-bottom: 20px;
    text-align: center;
}

.profile-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}

.profile-name {
    margin-top: 10px;
    font-size: 16px;
    color: #ecf0f1;
}

.sidebar-menu {
    list-style: none;
    padding: 0;
    width: 100%;
}

.sidebar-menu li {
    width: 100%;
}

.sidebar-menu li a {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    color: #ecf0f1;
    text-decoration: none;
    width: 100%;
}

.sidebar-menu li a:hover {
    background-color: #34495e;
}

.sidebar-icon {
    margin-right: 10px;
}

.sidebar-text {
    display: inline;
}

.sidebar-footer {
    margin-top: auto;
    padding: 20px;
    width: 100%;
    text-align: center;
}

.logout-button {
    background-color: #e74c3c;
    color: #ecf0f1;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logout-button .sidebar-icon {
    margin-right: 10px;
}

.logout-button:hover {
    background-color: #c0392b;
}

@media (max-width: 768px) {
    .sidebar {
        width: 71px;
    }

    .profile-img {
        width: 50px !important;
        height: 50px !important;
    }

    .profile-name {
        display: none; /* Ocultar nombre en pantallas pequeñas */
    }

    .sidebar-menu li a {
        padding: 10px;
        font-size: 14px;
        justify-content: center;
    }

    .sidebar-icon {
        margin-right: 0;
    }

    .sidebar-text {
        display: none;
    }

    .logout-button {
        padding: 5px;
        font-size: 14px;
        justify-content: center;
    }
}
