.container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
}

.controls {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
}

.controlItem {
    display: flex;
    align-items: center;
    gap: 10px;
}

.graficarButton {
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
}

.graficarButton button {
    padding: 10px 20px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.graficarButton button:hover {
    background-color: #45a049;
}

.chartContainer {
    width: 100%;
    max-width: 800px; /* Ajusta el ancho máximo si es necesario */
    margin: 0 auto; /* Centra el contenedor horizontalmente */
    margin-bottom: 40px; /* Espacio entre las gráficas */
    height: 400px; /* Asegúrate de que ambas gráficas tengan la misma altura */
}

.chartContainer h2 {
    text-align: center;
    margin-bottom: 20px; /* Espacio debajo del subtítulo */
}

@media (min-width: 600px) {
    .controls {
        flex-direction: row;
        flex-wrap: wrap;
    }
    .controlItem {
        flex: 1 1 30%;
        margin-right: 10px;
    }
    .graficarButton {
        flex: 1 1 100%;
    }
}

@media (min-width: 900px) {
    .controls {
        flex-direction: row;
    }
    .controlItem {
        flex: 1 1 20%;
    }
}

/* Estilos para el calendario */
.react-datepicker-wrapper {
    display: flex;
}

.react-datepicker__input-container {
    display: flex;
    align-items: center;
}

.react-datepicker__input-container input {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 16px;
}

.react-datepicker {
    font-family: Arial, sans-serif;
}

.react-datepicker__header {
    background-color: #4caf50;
    color: white;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    color: #000;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
    background-color: #4caf50 !important;
}
